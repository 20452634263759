import React from "react";
import PropTypes from "prop-types";

import Header from "./Header";
import Footer from "./Footer";

import * as styles from "../styles/layout.css";

export const Layout = (props) => {
  return (
    <div id="layout" className={styles.master}>
      <Header />
      <div className={styles.layout}>{props.children}</div>
      <Footer />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  region: PropTypes.string,
};

export default Layout;

