// import { faBars, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { faBars } from "@fortawesome/pro-regular-svg-icons/faBars";
import { faTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";
// import { faFacebookF, faInstagram, faPinterestP } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { vars } from "../styles/global.css";
import * as styles from "../styles/layout.css";
import { Link } from "gatsby";

import logo from "../images/logo.svg";

const Header = (props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [scrollPosition, setScrollPosition] = React.useState(0);

  const toggleNav = React.useCallback(() => {

    if (window.innerWidth >= vars.breakpoints.desktop) {
      return;
    }

    const layoutEl = document.querySelector("#layout");

    if (!isOpen) {
      setScrollPosition(window.scrollY);
      layoutEl.style.top = -window.scrollY + "px";
      layoutEl.style.position = "fixed";
    } else {
      layoutEl.style.position = "relative";
      layoutEl.style.top = 0;
      window.scrollTo({ left: 0, top: scrollPosition, behavior: 'instant'});
    }

    setIsOpen(!isOpen);
  }, [isOpen, scrollPosition]);


  React.useEffect(() => {
    if (window.innerWidth < vars.breakpoints.desktop) {
      document.documentElement.classList.toggle("modal-open", isOpen);
    } else {
      document.documentElement.classList.remove("modal-open");
    }
  }, [isOpen]);

  const handleNavClick = React.useCallback(() => {
    // setMobileMenu(mobileMenu === index ? -1 : index)
    // //console.log(el);
    // if (level === 2) {
    //   setIsOpen(false)
    //   setCanHover(false)
    // }
    const layoutEl = document.querySelector("#layout");
    layoutEl.style.position = "relative";
    layoutEl.style.top = 0;
    setIsOpen(false)
  }, [])

  return (
    <>
      <div className={`${styles.header} ${isOpen ? styles.headerOpen : ""}`}>
        <div
          className={`${styles.overlay} ${isOpen ? styles.overlayOpen : ""}`}
          onClick={toggleNav}
          role="dialog"
          aria-label="Main Menu"
        ></div>

        <div className={styles.headerInner}>
          {!isOpen && (
            <>
              <div className={styles.hamBox}>
                <button
                  className={styles.hamButton}
                  aria-label="Open navigation menu"
                  aria-haspopup="true"
                  aria-expanded={isOpen}
                  type="button"
                  onClick={toggleNav}
                >
                  <FontAwesomeIcon
                    icon={faBars}
                    style={{
                      fontSize: "1.2rem",
                      width: "20px",
                      height: "20px",
                    }}
                  />
                </button>
              </div>
              <div className={styles.headerLogo2}>
                {/* Maldives Guide */}
                <Link to="/"><img src={logo} alt="Maldives Guide Logo" height="48" width="160" /></Link>
              </div>
            </>
          )}
          <nav
            className={`${styles.navMenuContainer} ${
              isOpen ? styles.navMenuOpen : ""
            }`}
            role="navigation"
          >
            <div className={styles.close}>
              <button
                type={"button"}
                className={styles.closeButton}
                aria-label="Close navigation menu"
                aria-haspopup="true"
                aria-expanded={isOpen}
                onClick={toggleNav}
              >
                <FontAwesomeIcon
                  icon={faTimes}
                  style={{ fontSize: "1.2rem" }}
                />
              </button>
            </div>

            <div className={styles.headerLogo}>
              <Link to="/" onClick={toggleNav}><img src={logo} alt="Maldives Guide Logo" height="48" width="160"/></Link>
            </div>
            <ul className={styles.headerMenu}>
              {/* <li><Link to="/">Start Here</Link></li> */}
              {/* <li><Link to="/" onClick={handleNavClick}>Home</Link></li> */}
              <li><Link to="/resorts" onClick={handleNavClick}>Resorts</Link></li>
              {/* <li><Link to="/" onClick={handleNavClick}>Flights</Link></li> */}
              {/* <li><Link to="/airport" onClick={handleNavClick}>Airport</Link></li> */}
              {/* <li>Type of Trip</li> */}
              {/* <li>Honeymoons</li> */}
              {/* <li>Family Travel</li> */}
              {/* <li><Link to="/things-to-do" onClick={handleNavClick}>Things to Do</Link></li> */}
              {/* <li>
                <Link to="/offers" onClick={handleNavClick}>Offers</Link>
              </li>
              <li><Link to="/" onClick={handleNavClick}>Price Guide</Link></li> */}
              <li>
                <Link to="/travel-guide" onClick={handleNavClick}>Travel Guide</Link>
              </li>
              {/* <li>
                <Link to="/about" onClick={handleNavClick}>About</Link>
              </li> */}
            </ul>
            {/* <div className={styles.headerSocial}>
                <FontAwesomeIcon icon={faPinterestP} />
                <FontAwesomeIcon icon={faInstagram} />
                <FontAwesomeIcon icon={faFacebookF} />
            </div> */}
            {/* <div className={styles.headerSignup}>
              <div>Sign Up</div>
            </div> */}

            <div className={styles.navMenuFlair} onClick={toggleNav} role="dialog" aria-label="Close Menu">

            </div>
          </nav>
        </div>
      </div>
    </>
  );
};

Header.propTypes = {};

export default Header;
