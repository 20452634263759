import { Script } from "gatsby";
import React from "react";

import { Provider } from "react-redux";
import { store } from "./src/store";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const providersWrapper = ({ element, props }) => {
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Provider store={store}>{element}</Provider>
      </LocalizationProvider>

      {process.env.NODE_ENV === "production" && (
        <Script>
          {`
        (function(c,l,a,r,i,t,y){
          c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
          t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i+"?ref=bwt";
          y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
        })(window, document, "clarity", "script", "gb18u6075t");
      `}
        </Script>
      )}
    </>
  );
};

export default providersWrapper;
