import React from "react";
import Layout from "./src/components/Layout";

const pageWrapper = ({ element, props }) => {
  return (
    <>
      <Layout>{element}</Layout>
    </>
  );
};

export default pageWrapper;
